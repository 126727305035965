
import { Component, Vue } from 'vue-property-decorator';
import { getComponent } from '@/utils/helpers';

@Component({
  components: {
    UserSettings: () => getComponent('common/UserSettings'),
  },
})
export default class UserActivity extends Vue {
}
